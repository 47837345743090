import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import { Button, List, Cell } from 'vant'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import axios from './util/axiosConfig/axios'
import config from '../config'
import axios from 'axios'
import '../src/common/font.css'

Vue.use(VueParticles)
Vue.use(Button)
Vue.use(List)
Vue.use(Cell)
Vue.prototype.$http = axios
// Vue.prototype.$ajax = axios;

Vue.config.productionTip = false

Vue.use(ElementUI)

window.baseUrl = config.baseUrl
Vue.prototype.baseUrl = config.baseUrl

import adminApi from './util/js/common.js';
Vue.prototype.adminApi = adminApi;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
