export default {
    //本地存储
    setLocal: function(key, value) {
        if (typeof(value) == "object") {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, value);
        }
    },
    getLocalStr: function(key) {
        var defaultValue = null;
        if (arguments[1]) {
            defaultValue = arguments[1];
        }

        var res = localStorage.getItem(key);
        return res === null ? defaultValue : res;
    },
    getLocalObj: function(key) {
        var keyObj = localStorage.getItem(key);
        if (keyObj != 'undefined')
            return JSON.parse(keyObj);
        return null;
    },
    removeLocal: function(key) {
        localStorage.removeItem(key);
    },
    //解决对象的浅拷贝
    copyObj: function(obj) {
        return JSON.parse(JSON.stringify(obj))
    },
    //时间戳转时间
    dateFormat: function(date, format) {
        if (format == '' || !format) {
            format = 'yyyy.MM.dd hh:mm:ss'
        } else {
            let regStr1 = RegExp(/-/);
            let regStr2 = RegExp(/\//);
            if (format.match(regStr1)) {
                format = format.replace(/-/g, ".");
            } else if (format.match(regStr2)) {
                format = format.replace(/\//g, ".");
            }
        }
        //format = 'yyyy/MM/dd hh:mm:ss'	
        if (date == '' || date == null || date == undefined || date == 'null') {
            return '暂无';
        }
        if (date == 0) { 
            return '';
        }
        if (date.toString().length <= 10) {
            date = date * 1000 //时间戳为10位需*1000
        }
        var time = new Date(Number(date))
        var o = {
            "M+": time.getMonth() + 1, // month
            "d+": time.getDate(), // day
            "h+": time.getHours(), // hour
            "m+": time.getMinutes(), // minute
            "s+": time.getSeconds(), // second
            "q+": Math.floor((time.getMonth() + 3) / 3), // quarter
            "S": time.getMilliseconds() // millisecond
        }
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }
        return format;
    },
    getTimeDiff: function(endtime) {
        var t = endtime - Date.parse(new Date());
        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    },
    //参数过滤验证联系电话
    checkMobile: function(param) {
        return /^1[3|5|7|8][0-9]\d{4,8}$/.test(param)
    },
    //参数过滤验证email
    checkEmail: function(param) {
        return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(param)
    },
    //判断是否为空 
    isEmpty: function(data) {
        if ((data == '' || data == null || data == undefined || data == 'null') && data !== 0) {
            return true;
        } else {
            return false;
        }
    },
    // 过滤参数
    filterParam(keys, data) {
        var obj = {}
        for (const key in data) {
            for (let i = 0; i < keys.length; i++) {
                if (key == keys[i]) {
                    obj[key] = data[key]
                }
            }
        }
        return obj;
    },

    getData(obj1, obj2) {
        var arr = {};
        for (var key in obj1) {
            // 两个对象都有这个key，并且值不等
            if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
                arr[key] = obj1[key];
                arr.id = obj1.id;
                arr.carId = obj1.carId;
            }
        }
        return arr
    },
    // 参数非空校验
    /**
     *  @param array keys [{key:'a',name:'字段名a'}] 需要校验的 字段 数组
     *  @param Object data 参数 Json
     */
    checkValue(keys, data) {
        for (var i = keys.length - 1; i >= 0; i--) {
            if (this.isEmpty(data[key[i].key])) {
                return key[i].name + '为空'
            }
        }
    },

    //参数过滤
    checkParam: function(object) {
        for (const key in object) {
            if (this.isEmpty(object[key])) {
                delete object[key]
            }
        }
        return object;
    },
    //验证Object空值
    objEmpty: function(object) {
        for (const key in object) {
            if (this.isEmpty(object[key])) {
                return true;
            }
        }
        return false
    },
    // axios Get时的参数
    getParam: function(data) {
        return { params: data }
    },
    // axios Delet时的参数
    delParam: function(data) {
        return { data: data };
    },
    // url 参数  & 拼接,
    // ? 也给拼了啊
    urlCode(data) {
        let type = Object.prototype.toString;
        const arr = [];
        for (const key in data) {
            if (!this.isEmpty(data[key]) || type.call(data[key]) == '[object Boolean]') {
                var k = data[key]
                if (type.call(data[key]) == '[object Array]') {
                    for (let i = 0, len = k.length; i < len; i++) {
                        arr.push(`${key}=${k[i]}`);
                    }
                } else {
                    arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
                }
            }
        }

        return '?' + arr.join('&');
    },
    /** 
     * 树形结构数组转一维数组
     *  @param array tree 树形数组
     *  @param  string childName 子节点名称
     */
    toDimension: function(tree, childKey) {
        var arr = [];
        treeChild(tree)

        function treeChild(childParam) {
            for (var i = 0; i < childParam.length; i++) {
                arr.push(childParam[i]);
                if (childParam[i][childKey] && childParam[i][childKey].length > 0) {
                    treeChild(childParam[i][childKey])
                        // delete childParam[i][childKey] //删掉空数组
                } else {
                    // delete childParam[i][childKey] //删掉空数组
                    continue;
                }

            }
        }
        return arr;

    },
    /** 
     *  树形结构数组去除空的子节点
     *  @param array tree 树形数组
     *  @param string childName 子节点名称
     */
    deletEmpty: function(tree, childKey) {
        treeChild(tree);

        function treeChild(child) {
            for (var i = 0; i < child.length; i++) {
                if (child[i][childKey] == '') {
                    delete child[i][childKey] //删掉空数组
                    continue
                } else {
                    treeChild(child[i][childKey]);
                }
            }
        }
        return tree;

    },

    /** 
     *   一维数组转树形结构数组
     *   @param array dimension 一维数组
     *   @param string key 
     *   @param string parentKey 父节点名称
     */
    toTree: function(dimension, key, parentKey) {
        var arr = [];
        var len = dimension.length
        for (var i = 0; i < len; i++) {
            if (dimension[i][parentKey] == 0) {
                var obj = dimension[i];
                obj.children = getChildren(dimension[i][key])
                arr.push(obj);
            }
        }

        function getChildren(id) {
            var children = []
            for (var i = 0; i < len; i++) {
                if (dimension[i][parentKey] == id) {
                    var obj = dimension[i];
                    obj.children = getChildren(dimension[i][key])
                    children.push(obj)
                }
            }
            return children;
        }
        return arr;

    },
    toTreeDown: function(dimension, key, parentKey) {
        var arr = [];
        var len = dimension.length
        for (var i = 0; i < len; i++) {
            if (dimension[i][parentKey] == 0) {
                var obj = dimension[i];
                if (getChildren(dimension[i][key]).length != 0) { obj.children = getChildren(dimension[i][key]) }
                arr.push(obj);
            }
        }

        function getChildren(id) {
            var children = []
            for (var i = 0; i < len; i++) {
                if (dimension[i][parentKey] == id) {
                    var obj = dimension[i];
                    if (getChildren(dimension[i][key]).length != 0) { obj.children = getChildren(dimension[i][key]) }
                    children.push(obj)
                }
            }
            return children;
        }
        return arr;

    },
    // 过滤json 
    formatJson: function(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
    },
    //验证字段 
    /**
     * 
     *  @param array fields 数组 ['a','b','c','d']
     *  @param array arr  ['a','b']
     *  @return json {a:true,b:true,c:false,d:false} 
     */
    checkField: function(fields, arr) {
        let obj = {};
        for (let i = 0; i < arr.length; i++) {
            let isExist = fields.some(item => {
                return arr[i] == item
            })
            obj[arr[i]] = isExist;
        }
        return obj;
    },
    // 保留两位小数
    formatFloat: function(n) {
        var b = parseFloat(n).toFixed(2).toString();
        var c = b.split(".");
        var len = b.length;
        if (len <= 3) { return b; }
        var r = (len % 3);
        return r > 0 ? b.slice(0, r) + "," + b.slice(r, len).match(/\d{3}/g).join(",") + "." + c[1][0] + c[1][1] : b.slice(r, len).match(/\d{3}/g).join(",");
    },
    // float型小数点后两位数的方法
    toFloat(x) { 
        var f = parseFloat(x); 
        if (isNaN(f)) {  return;  } 
        f = Math.round(x * 100) / 100; 
        return f;
    },
    // 保留一位小数
    floatFormat: function(n) {
        var b = parseFloat(n).toFixed(1).toString();
        var c = b.split(".");
        var len = b.length;
        if (len <= 3) { return b; }
        var r = (len % 3);
        return r > 0 ? b.slice(0, r) + "," + b.slice(r, len).match(/\d{3}/g).join(",") + "." + c[1][0] + c[1][1] : b.slice(r, len).match(/\d{3}/g).join(",");
    },
    // 保留小数和千分号
    formatThousands: function(num) {
        if (!num) {
            return "0.00";
        }
        //判断是否有小数点
        var s = num.toString().indexOf(".");
        if (s == -1) { //是整数
            return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ".00";
        } else { //是小数
            var arr = num.toString().split(".");
            if (arr.length > 1 && arr[1].length < 2) { //一位小数
                return (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + arr[1] + "0";
            } else { //两位小数
                return (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + arr[1][0] + arr[1][1];
            }
        }
    },
    // 秒 转 时 分 秒
    timeDiff(result) {
        var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
        var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
        var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
        return result = h + ":" + m + ":" + s;
    },
    dateBackTime(result) {
        let timeArr = result.split(':');
        let timeInt = parseInt(timeArr[0]) * 3600 + parseInt(timeArr[1]) * 60 + parseInt(timeArr[2]);
        return timeInt;
    },
    //千分号  ¥¥¥¥
    formatNumerQianFen(num) {
        let parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    // 金额格式化 453465465465 ====>453,465,465,465.00
    numberFormat(number, decimals, dec_point, thousands_sep) {
        　　/*
        　　 * 参数说明：
        　　 * number：要格式化的数字
        　　 * decimals：保留几位小数
        　　 * dec_point：小数点符号
        　　 * thousands_sep：千分位符号
        　　 * */
        　　 number = (number + '').replace(/[^0-9+-Ee.]/g, '');
        　　 var n = !isFinite(+number) ? 0 : +number,
        　　 prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
         　　sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
         　　dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
         　　s = '',
        　　 toFixedFix = function(n, prec) {
         　　　　var k = Math.pow(10, prec);
         　　　　return '' + Math.ceil(n * k) / k;
         　　};
        
         　　s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        　　 var re = /(-?\d+)(\d{3})/;
        　　 while(re.test(s[0])) {
         　　　　s[0] = s[0].replace(re, "$1" + sep + "$2");
         　　}
        
         　　if((s[1] || '').length < prec) {
            　　 s[1] = s[1] || '';
             　　s[1] += new Array(prec - s[1].length + 1).join('0');
         　　}
          　　　　return s.join(dec);
         }
}