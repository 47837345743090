module.exports = {

  lintOnSave: false,
  // 关掉eslint
  devServer: {
    overlay: {
      warnings: false,
      errors: false
    }
  }
}

